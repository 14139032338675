import * as React from "react"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import profile from "../data/profile.json"
import projects from "../data/projects.json"
import brands from "../data/brands.json"

/**
 * Types
 */
interface IData {
    profile: IProfile;
    projects: [IProjects];
    brands: [string];
}

interface IProfile {
    fullName: string;
    email: string;
    telephone: string;
    headline: string;
    location: string;
    summary: [string];
    networks: INetworks;
}

interface IImage {
    path: string;
    caption: string;
}

interface INetworks {
    [key: string]: {
        url: string,
        title: string
    };
}

interface INetwork {
    url: string;
    title: string;
}

interface IHighlight {
    title: string;
    copy: string;
}

interface IProjects {
    featured: boolean;
    brand: string;
    title: string;
    roleTitle: string;
    roleType: string;
    duration: string;
    description: string;
    highlights: [IHighlight];
    images: [IImage];
}


/**
 * Init Vars
 */
const data: IData = {...profile, ...projects, ...brands};

const Project = (props: IProjects) => {
    const {featured = false, brand, roleTitle, roleType, duration, description, highlights} = props;

    return <div className={'portfolio-item'}>
        <StaticImage
            src="../images/thumbnails/accenture.jpeg"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fullWidth"
        />
    </div>
}


/**
 * Config
 */

const VR = {
    "settings": {
        "content": {
            "summary": true,
            "selectedExperience": false,
            "additonalExperience": false,
            "brandExposure": true,
            "references": true,
        }
    }
}

/**
 * Return Markup
 */
const IndexPage = () => {
    const {profile, projects, brands} = data;

    return (
        <main>
            <Helmet defer={false}>
                <title>
                    Vincent Roman - Fullstack Developer
                </title>
                <meta name="description" content="Vincent Roman - A fullstack developer with over two decades of experience as a self-motivated, self-taught, problem-solving skills. " />
                <link rel="canonical" href="https://www.vincentroman.com/" />
            </Helmet>
            <section>
                <h2>Projects</h2>
                <ul className={"portfolio"}>
                    {projects.filter(p => p.featured).map(project => <li><Project {...project} /></li>)}
                </ul>
            </section>
        </main>
    )
}

export default IndexPage
